<template>
  <div class="tw-flex tw-flex-col tw-gap-2">
    <h3 class="tw-text-xl c-gray-text tw-font-semibold mb-2">
      {{ greeting }}
      {{ user['custom:name'].substring(0, user['custom:name'].indexOf(' ')) }}
    </h3>

    <v-divider class="mb-1"></v-divider>

    <p class="c-blue-text mb-0">Welcome to APA Insurance</p>
    <p class="c-blue-text mb-0">
      Processing claims efficiently, effectively and faster
    </p>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'

export default {
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    greeting() {
      const currentHour = moment().hour()

      if (currentHour >= 0 && currentHour < 12) {
        return 'Good Morning,'
      } else if (currentHour >= 12 && currentHour < 16) {
        return 'Good Afternoon,'
      } else {
        return 'Good Evening,'
      }
    },
  },
}
</script>
